<template>
    <div id="app">
        <keep-alive include="consequence">
        <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>

    export default {
        name: 'App'

    }
</script>

<style lang="scss">
    * {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
    }
    html,body{
        height: 100%;
    }
    #app {
        height: 100%;
        font-family: PingFangSC-Medium;
    }
</style>
