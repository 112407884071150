import Vue from 'vue'
import Router from 'vue-router'
import consequence from '../pages/consequence.vue'
import claim from '../pages/claim.vue'
import liveStreaming from '../pages/liveStreaming.vue'
import queryRecord from '../pages/queryRecord.vue'
import realizeBrand from '../pages/realizeBrand.vue'
import rePurchase from '../pages/rePurchase.vue'
import traceAbility from '../pages/traceAbility.vue'
import goodsMsg from '../pages/goodsMsg.vue'
import baiduMap from '../pages/baiduMap.vue'
import directToApp from '../pages/directToApp.vue'

Vue.use(Router)

const router =  new Router({
    mode:"hash",
    routes: [
        {

            path: '/',
            redirect:'/consequence',
            name: 'consequence',
            component: consequence,
        },
        {

            path: '/consequence',
            name: 'consequence',
            component: consequence
        },
        {

            path: '/directToApp',
            name: 'directToApp',
            component: directToApp
        },


        {

            path: '/goodsMsg',
            name: 'goodsMsg',
            component: goodsMsg,
        },
        {

            path: '/baiduMap',
            name: 'baiduMap',
            component: baiduMap,
        },
        {
            path: '/claim',
            name: 'claim',
            component: claim,
        },
        {
            path: '/liveStreaming',
            name: 'liveStreaming',
            component: liveStreaming,
        },
        {
            path: '/queryRecord',
            name: 'queryRecord',
            component: queryRecord,
        },
        {
            path: '/realizeBrand',
            name: 'realizeBrand',
            component: realizeBrand,
        },
        {
            path: '/traceAbility',
            name: 'traceAbility',
            component: traceAbility,
        },
        {
            path: '/rePurchase',
            name: 'rePurchase',
            component: rePurchase,
        },
    ]
})
// router.beforeEach((to, from, next) => {
//     console.log(to)
//         if(!localStorage.getItem('token') && to.path != '/') {
//             // window.location.href = '/#/login'
//             next({ path: '/' })
//         } else {
//             next()
//         }
//
// })
export default router