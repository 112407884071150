<template>
  <div class="consequence">
    <header>
      <div class="closeBtn">
        <!--                <img src="../assets/images/close.png" alt="">-->
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="moreBtn">
        <!--                <img src="../assets/images/more.png" alt="">-->
      </div>
    </header>
    <main>
      <div class="canshow" v-if="canshow">
        <div class="service">
          <a href="tel:400-0033-513" style="display: block">
            <div class="service-wrap">
              <img src="../assets/images/service.png" alt="" />
              <span>客服</span>
            </div>
          </a>
        </div>
        <div class="goodsWarp">
          <div class="goodMsg">
            <div class="goodName" @click="toastEvent">
              {{ goodsName ? goodsName : "" }}
            </div>
            <div class="goodImg">
              <img :src="goodsImg" alt="" />
              <div class="authentication">
                <img
                  src="../assets/images/consequence/authentication.png"
                  alt=""
                />
                <!--                            <img src="../assets/images/consequence/qualityGood.png" alt="">-->
              </div>
            </div>
            <div class="securityCode">
              商品防伪码：{{ code ? code.split("-")[3] : null }}
            </div>
            <div class="queryTimes" v-show="inside">
              <div class="queryed" v-show="times >= 1 && times < 10">
                <div class="queryTime">该码已被查询</div>
                <div class="firstTimes">第一次查询：{{ firstTime }}</div>
              </div>

              <div class="frequentTime" v-show="times >= 10">
                <div class="frequentTop">
                  <div class="queryTime">该码已被查询</div>
                  <div class="firstTimes">第一次查询：{{ firstTime }}</div>
                </div>
                <div class="frquentBottom">
                  <div class="warning">
                    您好，该防伪码已被多次扫描，如不是您自己查询的，你可能买到假货了，请立即联系销售商和平台核实。
                  </div>
                </div>
              </div>
            </div>
            <div class="queryTimes" v-show="!inside">
              <div class="firstTime" v-show="times == 0">
                尚未比对，请打开手机NFC感应和扫码进行比对
              </div>
              <div class="repeatedTime" v-show="times >= 1 && times < 10">
                <div class="queryTime">
                  比对成功次数：<span
                    class="time"
                    @click="recordEvent('outside')"
                    >{{ times }}次</span
                  >
                </div>
                <div class="firstTimes">第一次比对：{{ firstTime }}</div>
              </div>
              <div class="frequentTime" v-show="times >= 10">
                <div class="frequentTop">
                  <div class="queryTime">
                    比对次数：<span class="time" @click="recordEvent('outside')"
                      >{{ times }}次</span
                    >
                  </div>
                  <div class="firstTimes">第一次比对：{{ firstTime }}</div>
                </div>
                <div class="frquentBottom">
                  <div class="warning">
                    该防伪码已被多次比对，如果不是您自己比对的，则存在假冒风险，请立即联系经销商或平台客服核实。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="traceWrap" v-show="inside">
            <div class="traceMsg" v-show="unElimanate">
              <div class="trace-left">
                <img src="../assets/images/consequence/traceCode.png" alt="" />
                <div class="traceCode">
                  <div><span class="tracecode">溯源码</span></div>
                  <!--                            <span>点击激活防伪码</span>-->
                </div>
              </div>
              <div class="trace-right">
                <button @click="elimanateCode">享用销码</button>
              </div>
            </div>
            <div class="traceMsg" v-if="!unElimanate">
              <div class="trace-left">
                <img src="../assets/images/consequence/trace.png" alt="" />
                <div class="traceCode">
                  <div>
                    <span class="tracecode">溯源码</span>
                    <span class="activeTime">{{ updateTime }}</span>
                  </div>
                  <span>防伪码已被激活</span>
                </div>
              </div>
              <div class="trace-right">
                <button class="enjoyed">已被享用</button>
              </div>
            </div>
          </div>

          <div class="viewTrace" @click="viewTrace">
            <div class="view-left">
              <img src="../assets/images/consequence/traceMsg.png" alt="" />
              <div class="viewMsg">
                <div>查看溯源信息</div>
                <span>产品信息、溯源信息、企业资质</span>
              </div>
            </div>
            <div class="view-right">
              <img src="../assets/images/consequence/arrow.png" alt="" />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="rePurchase" @click="repurchase">
            <div class="rePurchaseDiv">
              <img src="../assets/images/consequence/mall.png" alt="" />
              <div class="wrap">匠心古法·生态好茶</div>
            </div>

            <div class="realizeBtn">
              <span>购买</span>
              <img src="../assets/images/consequence/arrow1.png" alt="" />
            </div>
          </div>
          <div class="moreOptions">
            <div class="claim" @click="claimEvent">
              <div class="claim-title">
                <p>认领一亩茶园</p>
                <span>拥有您的专属茶园</span>
              </div>
              <img src="../assets/images/consequence/claim.png" alt="" />
            </div>
            <div class="liveStreaming" @click="liveEvent">
              <div class="claim-title">
                <p>核心产区</p>
                <span>地图显示</span>
              </div>
              <img src="../assets/images/consequence/live.png" alt="" />
            </div>
          </div>
        </div>
        <div class="brandMsg">
          <div class="brandMsg-top">
            <div class="brandImg" @click="realizeEvent">
              <img src="../assets/images/consequence/brand.png" alt="" />
            </div>
            <div class="line"></div>
            <div class="location">
              <div style="margin-bottom: 8px">
                武夷山寻境缘茶生态茶业有限公司
              </div>
              <div class="phone">电话： 400-0033-513</div>
            </div>
          </div>
          <div class="brandMsg-bottom">
            <button @click="realizeEvent">了解寻静缘茶</button>
          </div>
        </div>

        <footer>
          <div class="techSup">
            <div class="company">
              <img src="../assets/images/logo_icon.png" alt="" />
              <span>钦家</span>
            </div>
            提供技术支持
          </div>
        </footer>
      </div>
      <div class="noResult" v-if="!canshow">
        <p>该防伪码未入库，无法确认是真货</p>
        <p>请联系平台咨询。</p>
        <p>平台联系电话：<a href="tel:400-0033-513">4008-950-110</a></p>
      </div>
    </main>
  </div>
</template>

<script>
var fromTrace = false;
import { ImagePreview, Toast } from "vant";
import * as Api from "../api/home.js";
export default {
  name: "consequence",
  data() {
    return {
      unElimanate: true,
      times: null,
      firstTime: null,
      updateTime: null,
      code: null,
      title: "寻静茶 生态码 NFC芯片防伪",
      canshow: true,
      inside: null,
      goodsName: "",
      goodsImg: "",
      goodsDetail: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from);
    if (from.name == "traceAbility") {
      fromTrace = true;
    }
    next();
  },
  methods: {
    previewImg(e) {
      ImagePreview({
        // images:this.goodsDetail,
        images: [
          "https://isus-attendance.oss-cn-hangzhou.aliyuncs.com/isus/image/20220114/28c12cfa0ac04413a098495bf85e237b.jpg",
          "https://isus-attendance.oss-cn-hangzhou.aliyuncs.com/isus/image/20220114/f60dacdb13aa42b9b587aaf55eb65c38.jpeg",
        ],
        startPosition: e.target.getAttribute("index") * 1,
      });
    },
    viewTrace() {
      this.$router.push({
        path: "/goodsMsg",
        query: {
          code: this.code,
        },
      });
    },

    repurchase() {
      Api.remoteGetLinkUrl().then((res) => {
        const miniprogramLink = res.data?.urlLink || "";
        if (miniprogramLink) {
          location.href = miniprogramLink;
        } else {
          Toast.fail("跳转失败");
        }
      });
    },
    realizeEvent() {
      this.$router.push("/realizeBrand");
    },
    claimEvent() {
      this.$router.push("/claim");
    },
    liveEvent() {
      this.$router.push({
        path: "/baiduMap",
        query: {
          goodsName: this.goodsName,
        },
      });
    },
    recordEvent(type) {
      if (type == "inside") {
        this.$router.push({
          path: "queryRecord",
          query: {
            type: "inside",
          },
        });
      } else {
        this.$router.push({
          path: "queryRecord",
          query: {
            type: "outside",
          },
        });
      }
    },
    elimanateCode() {
      this.changeCodeStatus();
      this.unElimanate = false;
    },
    formatTime(time) {
      var date = new Date(time);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return [year, month, day].map(this.formatNumber).join("-");
      // return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
    },
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },
    toastEvent() {
      Toast.fail("登录信息失效，请重新登录");
    },
    getQueryTimes(code, ...arg) {
      Api.getQueryTimes({ markId: code, scanType: arg[0] }).then((res) => {
        if (res.data) {
          this.goodsImg = res.data.reportCover;
          this.goodsName = res.data.name;
          this.goodsDetail = res.data.goodsDetail || [];
          var packType = res.data.packType;
          var times;
          if (packType == 0) {
            this.inside = false;
            times = res.data.comparNum;
            if (this.$route.query.scanType == "NFC") {
              this.title = "寻静茶 生态码 NFC芯片防伪";
            } else {
              this.title = "寻静缘 生态茶 防伪码";
            }
            this.times = times;
            this.firstTime = res.data.firstTime;
          } else {
            if (fromTrace) {
              this.inside = true;
              this.title = "寻静缘 生态茶 防伪码";
              times = res.data.scanNum;
              var Elimanate = res.data.status;
              if (Elimanate == 0) {
                this.unElimanate = true;
              } else {
                this.unElimanate = false;
                this.updateTime = res.data.updateTime;
              }
              this.times = times;
              this.firstTime = res.data.firstTime;
            } else {
              this.$router.push({
                path: "/traceAbility",
                query: {
                  code: this.code,
                },
              });
            }
          }
        } else {
          this.canshow = false;
        }
      });

      // if(code){
      //     Api.getQueryTimes(code).then(res=>{
      //         var times = res.data.num
      //         this.times = times
      //         var Elimanate = res.data.status
      //         if(Elimanate==0){
      //             this.unElimanate = true
      //         }else{
      //             this.unElimanate = false
      //             this.updateTime =res.data.updateTime
      //         }
      //         this.firstTime = res.data.date
      //
      //     })
      // }
    },
    changeCodeStatus() {
      Api.changeCodeStatus(this.$route.query.c + "/" + 1).then((res) => {
        if (res.code == 1) {
          this.unElimanate = false;
          this.updateTime = res.data.updateTime;
        }
      });
    },
  },
  activated() {
    if (this.$route.query.c) {
      this.code = this.$route.query.c;
      this.getQueryTimes(
        this.$route.query.c,
        this.$route.query.scanType ? this.$route.query.scanType : ""
      );
    }
  },
};
</script>

<style scoped lang="scss">
.consequence {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: PingFang SC;
  header {
    height: 43px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    background: #fff;

    .closeBtn img {
      width: 18px;
      vertical-align: middle;
    }

    .moreBtn img {
      width: 18px;
      vertical-align: middle;
    }

    .title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  main {
    flex: 1;
    overflow: auto;
    background: #fff;
    box-sizing: border-box;
    position: relative;
    background: #f6f7f8;
    .noResult {
      text-align: center;
      font-size: 20px;
      background: #fff;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 150px;
      box-sizing: border-box;
      p {
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        a {
          text-decoration: underline;
          color: #02a7f0;
        }
      }
    }
    .canshow {
      .service {
        position: fixed;
        bottom: 100px;
        right: 20px;
        .service-wrap {
          width: 80px;
          height: 80px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;
          }
          span {
            color: #42b983;
          }
        }
      }
      .goodsWarp {
        background: #fff;
        margin: 5px 10px;
        border-radius: 8px;
        padding: 20px 12px 15px;
        margin-bottom: 13px;

        .goodMsg {
          display: flex;
          flex-direction: column;
          align-items: center;
          /*margin-left: 10px;*/
          /*margin-right: 10px;*/

          .goodName {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .goodImg {
            width: 200px;
            margin-bottom: 10px;
            position: relative;

            .authentication {
              position: absolute;
              right: -40px;
              width: 64px;
              height: 64px;
              bottom: -10px;
            }

            img {
              width: 100%;
              display: inline-block;
              margin: 0 auto;
            }
          }

          .securityCode {
            font-size: 16px;
            font-weight: 600;
            margin-left: 12px;
            margin-right: 12px;
            color: #333;
            letter-spacing: 1px;

            width: 100%;
            text-align: center;
            padding-bottom: 15px;
          }
          .queryTime {
            font-size: 14px;
            font-weight: 600;
            color: #f64b3d;
          }

          .queryTimes {
            width: 100%;
            margin: 10px 0 0px 0;
            border-top: 1px solid #eeeeee;
            padding-top: 10px;
            margin-bottom: 10px;
            .queryed {
              display: flex;
              padding: 0 20px;
              align-items: center;
              justify-content: space-between;
            }
            .firstTime {
              text-align: center;
              font-weight: 600;
              font-size: 14px;
              white-space: nowrap;
              span {
                font-size: 16px;
                padding-left: 5px;
                font-weight: 600;
                color: #f64b3d;
              }
            }

            .repeatedTime,
            .frequentTop {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;

              .time {
                font-size: 15px;
                color: #d9001b;
                margin-right: 5px;
                color: #f64b3d;
                font-weight: 600;
              }

              .firstTimes {
                color: #999999;
                font-size: 12px;
                white-space: nowrap;
                margin-left: 5px;
              }

              /*.times{*/
              /*    font-size: 19px;*/
              /*    color: #000;*/
              /*    font-weight: 600;*/
              /*}*/
            }

            .frquentBottom {
              font-size: 12px;
              margin-top: 5px;
              color: #f84f51;
            }
          }
        }

        .viewTrace {
          background: linear-gradient(144deg, #f6fdf9 0%, #eafaf0 100%);
          border-radius: 8px;
          height: 64px;
          display: flex;
          padding: 10px 12px;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;

          .view-left {
            display: flex;
            img {
              width: 44px;
              height: 44px;
              margin-right: 12px;
            }
            .viewMsg {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              div {
                color: #333333;
                font-size: 14px;
                font-weight: 600;
              }
              span {
                color: #999;
                font-size: 12px;
              }
            }
          }
          .view-right {
            width: 23px;
            height: 23px;
            background: linear-gradient(147deg, #78da89 0%, #68ca83 100%);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-left: 2px;
              width: 15px;
            }
          }
        }

        .traceMsg {
          background: linear-gradient(155deg, #f7faff 0%, #f5f8ff 100%);
          border-radius: 8px;
          height: 64px;
          display: flex;
          padding: 10px 12px;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .trace-left {
            display: flex;

            img {
              width: 44px;
              height: 44px;
              margin-right: 12px;
            }

            .traceCode {
              display: flex;
              flex-direction: column;
              justify-content: center;

              div {
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                .tracecode {
                  color: #333333;
                  font-size: 14px;
                  font-weight: 600;
                  margin-right: 5px;
                }

                .activeTime {
                  color: #999;
                  font-size: 10px;
                }
              }

              span {
                color: #999;
                font-size: 12px;
              }
            }
          }

          .trace-right {
            button {
              width: 96px;
              height: 30px;
              background: linear-gradient(147deg, #78da89 0%, #68ca83 100%);
              border-radius: 16px;
              line-height: 30px;
              text-align: center;
              color: #fff;
              font-size: 13px;
              border: none;
              font-family: PingFangSC-Medium;
            }

            .enjoyed {
              background: #c8c9cc !important;
            }
          }

          .eliminate,
          .viewTrace {
            background: rgb(2, 167, 240);
            width: 104px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
            color: #fff;
          }

          .eliminated {
            background: rgb(170, 170, 170);
            width: 104px;
            height: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 5px;
            color: #fff;
          }

          .outInterface {
            width: 180px;
          }

          img {
            img {
              width: 58px;
              height: 63px;
            }
          }
        }
      }
      .content {
        background: #fff;
        margin-bottom: 20px;
        margin: 5px 10px;
        border-radius: 8px;
        padding: 15px 12px 15px;
        .rePurchase {
          height: 80px;
          padding: 13px 12px;
          display: flex;
          justify-content: space-between;
          background: linear-gradient(204deg, #fefaf4 0%, #ffefd8 100%);
          box-sizing: border-box;
          border-radius: 8px;

          .rePurchaseDiv {
            display: flex;
            height: 100%;
            align-items: center;
            img {
              width: 40px;
              height: 25px;
            }
            .wrap {
              font-size: 18px;
              margin-left: 15px;
              white-space: nowrap;
              font-weight: 600;
            }
          }

          .realizeBtn {
            color: #999;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 16px;
            img {
              width: 20px;
              height: 20px;
              margin-left: 2px;
            }
          }
        }

        .moreOptions {
          display: flex;

          margin-top: 15px;
          box-sizing: border-box;
          .claim {
            height: 88px;
            display: flex;
            align-items: center;
            padding-left: 8px;
            background: #f8f9fa;
            width: 50%;
            justify-content: space-between;
            border-radius: 8px;
            .claim-title {
              p {
                color: #333;
                font-size: 13px;
                font-weight: 600;
                height: 30px;
                line-height: 30px;
              }
              span {
                color: #999;
                font-size: 12px;
              }
            }
            img {
              width: 50px;
              height: 50px;
              margin-right: 5px;
            }
            span {
              font-size: 16px;
              color: rgb(212, 35, 122);
            }
          }

          .liveStreaming {
            height: 88px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            padding-left: 8px;
            background: #f8f9fa;
            width: 50%;
            justify-content: space-between;
            border-radius: 8px;
            .claim-title {
              p {
                color: #333;
                font-size: 13px;
                font-weight: 600;
                height: 30px;
                line-height: 30px;
              }
              span {
                color: #999;
                font-size: 12px;
              }
            }
            img {
              width: 50px;
              height: 50px;
              margin-right: 5px;
            }

            span {
              font-size: 16px;
              color: rgb(252, 131, 8);
            }
          }
        }
      }

      .goodsDetail {
        background: #fff;
        margin: 5px 10px;
        border-radius: 8px;
        padding: 0px 12px 15px;
        margin-bottom: 13px;
        margin-top: 10px;
        .goodsTitle {
          text-align: center;
          color: #999;
          font-size: 12px;
          height: 40px;
          line-height: 40px;
        }
        .detailImg {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .brandMsg {
        height: 108px;
        margin-left: 10px;
        margin-right: 10px;
        background: #fafafc;
        border-radius: 8px;
        padding-left: 25px;
        padding-top: 14px;
        padding-right: 25px;
        .brandMsg-top {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
          .brandImg {
            margin-left: 12px;
            img {
              width: 120px;
              height: 47px;
            }
          }
          .line {
            margin: 0 25px;
            width: 1px;
            height: 27px;
            background: #ddd;
          }
          .location {
            text-align: left;
            font-size: 12px;
            color: #334;
            .phone {
              color: #999;
              font-size: 11px;
            }
          }
        }
        .brandMsg-bottom {
          padding-top: 10px;
          button {
            width: 296px;
            height: 32px;
            background: #f2f3f5;
            border-radius: 4px;
            line-height: 32px;
            text-align: center;
            color: #999;
            font-size: 12px;
            border: none;
            font-family: PingFangSC-Medium;
          }
        }
      }

      footer {
        height: 56px;
        padding-top: 10px;

        .techSup {
          text-align: center;
          color: #999;

          .company {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
