<template>
    <div class="realizeBrand">
        <topHeader msg="寻静缘茶"></topHeader>
        <main>
            <img src="../assets/images/realizeBrand/1.png" alt="">
            <img src="../assets/images/realizeBrand/2.jpg" alt="">
            <img src="../assets/images/realizeBrand/3.jpg" alt="">
            <img src="../assets/images/realizeBrand/4.jpg" alt="">
        </main>
        <footer @click="focus">
            关注公众号
        </footer>
    </div>
</template>

<script>
    import topHeader from "../components/topHeader.vue";
    export default {
        name: "realizeBrand",
        components:{
            topHeader
        },
        methods:{
            focus(){
                window.open('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg3NDE5MjIwMw==#wechat_redirect')
            }
        }
    }
</script>

<style scoped lang="scss">
.realizeBrand{
    height: 100%;
    display: flex;
    flex-direction:column;
    font-family: PingFang SC;
    main{
        flex: 1;
        overflow:auto;
        img{
            width: 100%;
        }
    }
    footer{
        height: 1.28rem;
        line-height: 1.28rem;
        background: linear-gradient(
                        163deg, #78DA89 0%, #68CA83 100%);
        margin: 0.266667rem 0.266667rem;
        text-align: center;
        color: #fff;
        border-radius: 0.133333rem;
        font-size: 0.373333rem;
    }

}
</style>