import { render, staticRenderFns } from "./goodsMsg.vue?vue&type=template&id=2493622f&scoped=true&"
import script from "./goodsMsg.vue?vue&type=script&lang=js&"
export * from "./goodsMsg.vue?vue&type=script&lang=js&"
import style0 from "./goodsMsg.vue?vue&type=style&index=0&id=2493622f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2493622f",
  null
  
)

export default component.exports