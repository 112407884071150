import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible'
// import wxInit from './wxsdk'
// wxInit.install()
Vue.config.productionTip = false
import '@/assets/css/font.css'; // 引入字体样式
import router from './router'
Vue.config.ignoredElements = ['wx-open-launch-weapp']
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
