import * as API from "./index";
export const remoteGetLinkUrl = (params) => {
  return API.GET("/remote/getLinkUrl", params);
};
//获取nfc/二维码 对比/扫码次数
export const getQueryTimes = (params) => {
  return API.GET("/remote/markInfo", params);
};
export const changeCodeStatus = (params) => {
  return API.GET("/temp/qr/update", params);
};
export const getTrackMsg = (params) => {
  return API.GET("/mark/trackByMarkId", params);
};
export const getProcuctMsg = (params) => {
  return API.GET("/mark/productByMarkId", params);
};
export const getQualificationMsg = (params) => {
  return API.GET("/mark/qualificationByMarkId", params);
};

// export const getQueryTimes=(params)=>{
//     return API.GET('/temp/qr/info',params)
// }
// export const changeCodeStatus=(params)=>{
//     return API.GET('/temp/qr/update',params)
// }
