<template>
  <div class="traceAbility">
    <topHeader msg="生产工艺溯源"></topHeader>
    <main>
      <div class="securityCode">
        一物一码：{{ code ? code.split("-")[3] : null }}
      </div>
      <div class="process">
        <processItem1
          v-for="(item, index) in list"
          :class="`processItem${index + 1} singleProcess`"
          :key="index"
          :imgSrc="item.img"
          :single="(index + 1) % 2 == 0 ? false : true"
          :isLast="index + 1 == list.length ? true : false"
          :name="item.name"
          :time="item.date"
        ></processItem1>
      </div>
      <div class="purchase" @click="repurchase">
        <button>立即购买</button>
      </div>
    </main>
  </div>
</template>

<script>
import topHeader from "../components/topHeader.vue";
import processItem1 from "../components/1-processItem1.vue";
import * as API from "../api/home.js";
import { Toast } from "vant";
import * as Api from "@/api/home";

export default {
  name: "traceAbility",
  components: { topHeader, processItem1 },
  data() {
    return {
      list: [],
      code: null,
    };
  },
  methods: {
    repurchase() {
      Api.remoteGetLinkUrl().then((res) => {
        const miniprogramLink = res.data?.urlLink || "";
        if (miniprogramLink) {
          location.href = miniprogramLink;
        } else {
          Toast.fail("跳转失败");
        }
      });
    },
  },
  mounted() {
    this.code = this.$route.query.code;

    API.getTrackMsg({ markId: this.code }).then((res) => {
      console.log(res.data);
      if (res.data.length > 0) {
        this.list = res.data;
        setTimeout(function () {
          let processItems = document.querySelectorAll(".singleProcess");
          console.log(processItems);
          for (let i = 0; i < processItems.length; i++) {
            setTimeout(function () {
              processItems[i].style.opacity = 1;
            }, 1000 + i * 1000);
          }
        }, 0);
      } else {
        Toast.fail("暂无溯源信息");
      }
    });
  },
};
</script>

<style scoped lang="scss">
.traceAbility {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f7f8;
  font-family: PingFang SC;
  main {
    flex: 1;
    overflow: auto;
    padding: 20px 0px 0;
    width: 100%;
    box-sizing: border-box;
    .securityCode {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

    .process {
      display: flex;
      flex-wrap: wrap;
      padding: 100px 0px 0 45px;
    }
    .purchase {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 40px 0 20px;
      button {
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: rgba(163, 0, 20, 1);
        color: #fff;
        border-radius: 5px;
        margin-left: 5px;
        border: none;
        background: linear-gradient(147deg, #78da89 0%, #68ca83 100%);
      }
    }
  }
}
.singleProcess {
  opacity: 0;
  transition: all 1s;
}
</style>
