<template>
    <div class="rePurchase">
        <topHeader msg="产品介绍"></topHeader>
        <main>
            <img src="../assets/images/rePurchase/1.png" class="goodImg" alt="">
            <img src="../assets/images/rePurchase/2.png" class="goodImg"  alt="">
            <img src="../assets/images/rePurchase/3.png" class="goodImg"  alt="">
            <img src="../assets/images/rePurchase/4.png" class="goodImg"  alt="">
            <img src="../assets/images/rePurchase/5.png" class="goodImg"  alt="">
            <img src="../assets/images/rePurchase/6.png" class="goodImg"  alt="">
            <div class="btnGroups">
                <div class="addCart" @click="goApp">加入购物车</div>
                <div class="immediatelyBuy" @click="goApp">立即购买</div>
<!--                <wx-open-launch-weapp-->
<!--                        id="launch-btn"-->
<!--                        username="gh_cff61fed474b"-->
<!--                        path="pages/index/index.html"-->
<!--                >-->
<!--                    <template>-->
<!--                        <button class="immediatelyBuy">打开小程序</button>-->
<!--                    </template>-->
<!--                </wx-open-launch-weapp>-->
            </div>
        </main>
    </div>
</template>

<script>
    import topHeader from "../components/topHeader.vue";
    export default {
        name: "rePurchase",
        components:{
            topHeader
        },
        created() {

        },
        methods:{

        },
        mounted() {
            // this.get_wx()
        }
    }
</script>

<style scoped lang="scss">
    .rePurchase{
        display: flex;
        flex-direction: column;
        height: 100%;
        font-family: PingFang SC;
        main{
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .goodImg{
                width: 100%;

            }
            .btnGroups{
                height: 60px;
                line-height: 40px;
                display: flex;
                justify-content: flex-end;
                .addCart,.immediatelyBuy{
                    width: 140px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: rgba(163, 0, 20, 1);
                    color:#fff;
                    border-radius: 5px;
                    margin-left: 5px;
                }

            }
        }
    }

</style>