<template>
    <div class="claim">
        <topHeader msg="认领一亩茶园"/>
        <main>
            <div class="banner">
                <img src="../assets/images/claim/1.png" alt="">
            </div>
            <div class="introduce">
                <div class="title">
                   <span>茶缘新玩法-认领一亩茶园</span>   <img src="../assets/images/claim/5.png" alt="">
                    <div class="limit">限量500亩</div>
                </div>
                <div class="methods">
                    <div class="method-item">
                        <img src="../assets/images/claim/2.png" alt="">
                        <span>优选</span>
                        <span>武夷生态茶</span>
                    </div>
                    <div class="method-item">
                        <img src="../assets/images/claim/3.png" alt="">
                        <span>专属</span>
                        <span>私人定制</span>
                    </div>
                    <div class="method-item">
                        <img src="../assets/images/claim/4.png" alt="">
                        <span>轻松</span>
                        <span>当茶园主</span>
                    </div>
                </div>
                <div class="legalRights">
                    <div class="specRights">
                        <img src="../assets/images/claim/6.png" alt="">
                    </div>
                    <div class="legalRight-item">
                        1、茶园主认证神秘礼品配送到家;
                    </div>
                    <div class="legalRight-item">
                        2、享受22年明前优选50斤武夷生态茶成本价；
                    </div>
                    <div class="legalRight-item">
                        3、 茶园挂牌-专属您的一亩茶园；
                    </div>

                    <div class="legalRight-item">
                        4、享武夷山茶园之旅一次（免费别墅住宿），参观您的一亩茶园；
                    </div>
                    <div class="legalRight-item">
                        5、包装私人定制，公司名/人名注入包装；
                    </div>
                    <div class="legalRight-item">
                        6、免费一年仓储服务；
                    </div>
                    <div class="legalRight-item">
                        7、免费一年内一件代发服务。
                    </div>
                </div>
            </div>

        </main>
        <footer>
            敬请期待
        </footer>
    </div>
</template>

<script>
    import topHeader from '../components/topHeader.vue'

    export default {
        name: "claim",
        components: {topHeader},
    }
</script>

<style scoped lang="scss">
    .claim {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-family: PingFang SC;
        main {
            flex: 1;
            overflow: auto;
            background: #fff;
            .banner {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            .introduce {
                margin-top: 20px;
                padding: 0 10px 10px 10px;

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    text-align: center;
                    img{
                        width: 13px;
                        vertical-align: middle;
                    }
                    .limit {
                        text-align: center;
                        color: #999;
                        font-size: 12px;
                        height: 30px;
                        line-height: 30px;
                    }
                }

                .methods {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    padding: 0 10px;
                    margin-top: 40px;

                    .method-item {
                        width: 84px;
                        height: 84px;
                        border-radius: 50%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
                        img{
                            margin-bottom: 10px;
                            width: 52px;
                            height: 52px;
                        }
                        span{
                            margin-bottom: 3px;
                        }
                    }
                }

                .legalRights {
                    padding: 24px 0px 0 10px;
                    margin-top: 60px;
                    border-radius: 16px;
                    border: 1px solid #68CA83;
                    background: #F6F7F8;
                    position: relative;
                    .specRights {
                        position: absolute;
                        left: -1px;
                        top: -22px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        img{
                            width: 114px;
                        }
                    }

                    .legalRight-item {
                        margin-bottom: 8px;
                        font-size: 10px !important;
                    }
                }

            }
        }

        footer {
            height: 48px;
            line-height: 48px;
            background: linear-gradient(163deg, #78DA89 0%, #68CA83 100%);;
            margin: 10px 10px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
            font-size: 14px;
        }
    }

</style>