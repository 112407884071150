<template>
    <div id="allmap" ref="mymap"></div>
</template>

<style scoped>
    #allmap {
        width: 100%;
        height: calc(100vh - 50vw);
        margin-bottom: 9.3333vw;
    }
    .map-wrap /deep/ .anchorBL{
        display: none;
    }

</style>

<script>
    import loc  from '../assets/images/te1a.png'
    import jjy  from '../assets/images/jjy.jpeg'
    import mty  from '../assets/images/mty.jpeg'
    import lzsx  from '../assets/images/1.jpg'
    import nlk  from '../assets/images/nlk.jpg'

    export default {
        mounted(){
            var goodsName = this.$route.query.goodsName
            console.log(goodsName)
            var aroundList = []
            if(goodsName == '马头岩肉桂'){
                aroundList = [
                    {
                        point: "117.972799|27.669384",
                        userName: "马头岩肉桂",
                        atlas: loc,
                        userId: '0',
                        alise:'高端品质 有机核心产区马头岩肉桂',
                        address:'weixin://dl/business/?t=Dmbg1BSGWXn',
                        pic:jjy
                    },
                    ]
            }
            if(goodsName == '牛栏坑肉桂'){
                aroundList = [
                    {
                        point: "117.971799|27.662384",
                        userName: "牛栏坑肉桂",
                        atlas: loc,
                        userId: '3',
                        alise:'高端品质 有机核心产区牛栏坑肉桂',
                        address:'weixin://dl/business/?t=IGi244jvesj',
                        pic:nlk
                    },
                ]
            }
            if(goodsName == '老枞水仙'){
                aroundList = [
                    {
                        point: "117.972799|27.669384",
                        userName: "金枞水仙",
                        atlas: loc,
                        userId: '2',
                        alise:'高端品质 有机核心产区金枞水仙',
                        address:'weixin://dl/business/?t=s0zouQddxGl',
                        pic:lzsx
                    },
                ]
            }
            if(goodsName == '金交椅肉桂'){
                aroundList = [
                    {
                        point: "117.975933|27.675276",
                        userName: "金交椅肉桂",
                        atlas: loc,
                        userId: '1',
                        alise:"高端品质 有机核心产区金交椅肉桂",
                        address:'weixin://dl/business/?t=vxy4VY7zoIp',
                        pic:mty
                    },
                ]
            }
            this.initMap(aroundList)
            setTimeout(function () {
                var list = document.querySelectorAll('.anchorBL')
                list.forEach(item=>{
                    item.style.display = 'none'
                })
            },100)
        },
        data () {
            return {
                center: {
                    lng: 117.963042,
                    lat: 27.668108
                },
                locationIcon: {
                    url: loc,
                    size: {width: 42, height: 42}
                },
                ready:false,
                // 附近的人
                aroundList: [
                    // {
                    //     point: "117.972799|27.669384",
                    //     userName: "马头岩",
                    //     atlas: loc,
                    //     userId: '0',
                    //     alise:'高端品质 有机核心产区马头岩肉桂',
                    //     address:'weixin://dl/business/?t=Dmbg1BSGWXn',
                    //     pic:jjy
                    // },
                    // {
                    //     point: "117.975933|27.675276",
                    //     userName: "金交椅",
                    //     atlas: loc,
                    //     userId: '1',
                    //     alise:"高端品质 有机核心产区金交椅肉桂",
                    //     address:'weixin://dl/business/?t=vxy4VY7zoIp',
                    //     pic:mty
                    // },

                ],
            }
        },
        methods: {
            initMap: function(aroundList) {
                this.createMap(aroundList)
                this.addMapControl()

            },
            // 创建地图
            createMap: function(aroundList) {
                var that = this
                var map = new BMap.Map(this.$refs.mymap)
                var point = new BMap.Point(this.center.lng,this.center.lat)
                map.centerAndZoom(point,14)
                window.map = map
                map.addEventListener('tilesloaded',()=>{
                    that.addAround(aroundList)
                })
            },
            // 添加控件
            addMapControl(){
                //向地图中添加缩放控件
                var ctrl_nav = new BMap.NavigationControl({anchor:BMAP_ANCHOR_BOTTOM_RIGHT,type:BMAP_NAVIGATION_CONTROL_LARGE});
                map.addControl(ctrl_nav);
                // 创建当前用户标注
                var ctrl_marker = new BMap.Point(this.center.lng,this.center.lat)
                var myIcon = new BMap.Icon(loc,new BMap.Size(40,49))

                // var marker_one = new BMap.Marker(ctrl_marker,{icon:myIcon})
                // map.addOverlay(marker_one)
                // 创建一个圆
                // var circle = new BMap.Circle(ctrl_marker,500,{strokeColor:"#f3c6b1",fillColor:"#f3c6b1", strokeWeight:1, strokeOpacity:0.5})
                // map.addOverlay(circle);
            },
            // 添加一个位置标记点
            addMarker: function(point){
                var marker = new BMap.Marker(point)
                map.addOverlay(marker,15)
            },
            //  加载附近的人信息
            addAround: function(markerList) {
                if(markerList.length>0){
                    for(var i=0;i<markerList.length;i++){
                        // 经度
                        var info1 = markerList[i].point.split('|')[0]
                        // 纬度
                        var info2 = markerList[i].point.split('|')[1]
                        // 绘制附近人点坐标
                        var pointA = new BMap.Point(this.center.lng,this.center.lat)
                        var point = new BMap.Point(info1,info2)
                        var myIcon = new BMap.Icon(markerList[i].atlas,new BMap.Size(40,49))
                        myIcon.setImageSize(new BMap.Size(50,50));//设置图标大小
                        var marker = new BMap.Marker(point,{icon:myIcon})
                        map.addOverlay(marker)
                        //悬浮提示信息
                        var content = {
                            userName:markerList[i].userName,
                            atlas: markerList[i].atlas,
                            userId: markerList[i].userId,
                            pic:markerList[i].pic,
                            address:markerList[i].address,
                            alise:markerList[i].alise
                        }
                        map.addOverlay(marker)
                        this.addClickHandler(content,marker)
                    }
                } else{
                    return
                }
            },
            //点击提示信息
            addClickHandler: function(content,marker){
                var _this = this
                var sContent = `<div  id="ssst" style="display: flex;flex-direction: row;align-items: center;width: 50.3333vw;">
          <img src="${content.pic}" alt="" style="width: 12.5333vw;height: 12.5333vw;border-radius: 50%;margin-right: 2.2667vw;">
          <div>
            <p style="font-size: 4vw;">${content.userName}</p>
            <p style="font-size: 3.2vw;color: #999;margin-top: 1.3333vw;">${content.alise}</p>
          </div>
          </div>`
                var userid = content.address
                var infoWindow = new BMap.InfoWindow(sContent)
                marker.addEventListener("click",function(e){
                    e.preventDefault = true
                    e.stop
                    this.openInfoWindow(infoWindow)
                    setTimeout(() => {
                        e.preventDefault = true
                        e.stop
                        _this.chatWith(userid)
                    }, 0);
                })
                    marker.openInfoWindow(infoWindow)
                setTimeout(function () {
                    var ssst = document.getElementById('ssst')
                    ssst.addEventListener('click',function() {
                        location.href = userid
                    })
                },1000)

            },
        }
    }
</script>