import axios from 'axios';
import { Toast } from 'vant';

console.log(process.env.IS_ENV)
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // config.headers['Authorization'] = localStorage.getItem('token')
    // 在发送请求之前做些什么;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use( (res)=> {
    // 对响应数据做点什么;
    if (res.data.code == 1) {
        return res.data
    } else if (res.data.code == -2) {
        Toast.fail('登录信息失效，请重新登录');
        // window.location.href = '#/'
        // localStorage.clear()
        // return Promise.reject(res.data)
    }
    else {
        // 统一处理提示
        Toast.fail(res.data.msg);
        return Promise.reject(res.data)
    }

}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// let baseUrl="https://prod.mm-iworld.com/dev-print";
// let baseUrl="https://prod.mm-iworld.com/dev-print";


let baseUrl="https://cloud.mm-iworld.com/tea";




export const GET=(url,params)=>{
    return axios.get(`${baseUrl}${url}`, {params:params}).then((data)=>{return data})
}
// export const GET=(url,params)=>{
//     return axios.get(`${baseUrl}${url}/${params}`).then((data)=>{return data})
// }
export const POST=(url,params,headers)=>{
    return axios.post(`${baseUrl}${url}`,params,headers).then((data)=>{ return data})
}
export const Del=(url,params,headers)=>{
    return axios.get(`${baseUrl}${url}`+params+';jsessionid='+localStorage.getItem("sessionId"),headers).then((data)=>{ return data})
}