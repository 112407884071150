<template>
    <div class="queryRecord">
        <topHeader :msg="msg"/>
        <main>
            <div class="banner">
                <img src="../assets/images/queryRecord/bg.png" alt="">
                <span class="">钦家防伪码溯源防伪</span>
                <div>保障消费者和商家的共同利益</div>

            </div>
            <div class="list">
                <div class="record"><img src="../assets/images/queryRecord/time.png" alt=""> 2020-11-26 17:00 在北京第1次扫码
                </div>
                <div class="record"><img src="../assets/images/queryRecord/time.png" alt=""> 2020-11-26 17:00 在北京第2次扫码</div>

            </div>
            <div class="explain">
                同一IP，同一设备只计入1次
            </div>
        </main>
    </div>
</template>

<script>
    import topHeader from '../components/topHeader.vue'

    export default {
        name: "queryRecord",
        components: {topHeader},
        data() {
            return {
                msg: '扫码记录'
            }
        },
        mounted() {
            console.log(this.$route.query.type)
        }
    }
</script>

<style scoped lang="scss">
    .queryRecord {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #F6F7F8;
        font-family: PingFang SC;
        main {
            padding-top: 10px;

            .banner {
                position: relative;
                padding: 0 12px;

                img {
                    height: 124px;
                    width: 351px;
                }

                span {
                    position: absolute;
                    left: 30px;
                    top: 36px;
                    margin: auto;
                    width: 170px;
                    text-align: left;
                    height: 35px;
                    color: #fff;
                    font-size: 16px;
                }
                div {
                    position: absolute;
                    left: 30px;
                    top: 62px;
                    margin: auto;
                    text-align: left;
                    height: 35px;
                    color: #fff;
                    font-size: 16px;
                }
            }

            .list {
                padding: 10px 30px;
                font-size: 13px;

                .record {
                    height: 30px;
                    line-height: 30px;

                    img {
                        width: 12px;
                        vertical-align: middle;
                        margin-right: 15px;
                    }
                }

            }
            .explain{
                text-align: center;
                margin: 20px 0 ;
            }
        }
    }
</style>