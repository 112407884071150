<template>
    <div class="content">
        <header>
            <div class="backBtn" @click="backEvent">
                <img src="../assets/images/back.png" alt="">
            </div>
            <div class="title">{{msg}}</div>
            <div style="width: auto;opacity: 0" > 123</div>
        </header>
    </div>
</template>

<script>

    export default {
        name: "topHeader",
        props:{
            msg:{
                type:String
            }
        },
        methods:{
            backEvent(){
                if(this.$route.query.code){
                    this.$router.push({
                        path:'/consequence',
                        query:{
                            c:this.$route.query.code
                        }
                    })
                }else{
                    this.$router.back(-1)

                }
            }
        }
    }
</script>

<style scoped lang="scss">
.content{
    height: 43px;
    width: 100%;

    background: #fff;
    header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding:0 10px;
        width: 100vw;
        box-sizing: border-box;
        img{
            width: 18px;
            vertical-align: middle;
        }
        .title{
            font-size: 16px;
            font-weight: 600;
        }
    }
}
</style>