<template>
    <div class="goodsMsg">
        <topHeader msg="产品信息"/>
        <main>
            <div class="msg-item">
                <div class="title">
                    品名: <span class="value">{{produce.name}}</span>
                </div>
                <div class="title">
                    配料： <span class="value">{{produce.mixed}}</span>
                </div>
            </div>
            <div class="msg-item">
                <div class="title">
                    质量等级： <span class="value">{{produce.quality}}</span>
                </div>
                <div class="title">
                    净含量： <span class="value">{{produce.netContent}}g</span>
                </div>
            </div>
            <div class="msg-item">
                <div class="title">
                    保质期： <span class="value">剩{{produce.leftTime}}</span>
                </div>
            </div>
            <div class="msg-item">
                <div class="titles">
                    生产日期： <span class="value trace" @click="trace">点击查看生产溯源</span>
                </div>

            </div>
            <div class="msg-item">
                <div class="titles">
                    产品标准号： <span class="value">{{produce.standardNo}}</span>
                </div>

            </div>
            <div class="msg-item">
                <div class="titles">
                    产品生产许可证： <span class="value">{{produce.licence}}</span>
                </div>
            </div>
            <div class="msg-item">
                <div class="titles">
                    生产地址： <span class="value">{{produce.address}}</span>
                </div>
            </div>
            <div class="qualityDiv"  @click="previewImg" >
                <div class="quality">公司资质</div>
                <div class="detailImg">
                    <img v-for="(item,index) in qualificationList"  :src="item" alt="" :index="index" :key="index">
                </div>

            </div>
        </main>

    </div>
</template>

<script>
    import topHeader from '../components/topHeader.vue'
    import {ImagePreview} from 'vant';
    import * as Api from "@/api/home";
    import moment from 'moment'
    export default {
        name: "goodsMsg",
        components: {topHeader},
        data(){
            return{
                code:null,
                produce:'',
                qualificationList:[]
            }
        },
        methods:{
          trace(){
              this.$router.push({
                  path:'/traceAbility',
                  query:{
                      code:this.code
                  }
              })
          },
            previewImg(e){
                ImagePreview({
                    images:this.qualificationList,
                    startPosition: e.target.getAttribute('index') * 1
                });
                // ImagePreview({
                //     images: [
                //         "https://www.513rocktea.com/rd/img/21c79fc.png",
                //         "https://www.513rocktea.com/rd/img/c3ae808.png",
                //         "https://www.513rocktea.com/rd/img/010171b.png",
                //         "https://www.513rocktea.com/rd/img/8e82c60.png",
                //         "https://www.513rocktea.com/rd/img/4ad5efd.png",
                //         "https://www.513rocktea.com/rd/img/5a6675e.png",
                //     ],
                //     startPosition: e.target.getAttribute('index') * 1
                // });
            },
            getProcudeMsg(code){
                Api.getProcuctMsg({markId:code}).then(res=>{
                    var time = res.data.createTime
                   var endTime =  moment(time).add(30 * res.data.period, 'days').format('yyyy-MM-DD');
                    console.log(endTime)
                    var d1 = new Date(endTime)
                    var d2 = new Date(moment(time).format('yyyy-MM-DD'))
                    var duration = d1-d2
                    this.produce = res.data
                    this.produce['leftTime'] = this.computeTime(duration)
                })
            },
            computeTime(timestampDiff){
                let computeTime = timestampDiff / 1000
                let year = Math.floor(computeTime / 86400 / 365);
                computeTime = computeTime % (86400 * 365);
                let month = Math.floor(computeTime / 86400 / 30);
                computeTime = computeTime % (86400 * 30);
                let day = Math.floor(computeTime / 86400);
                computeTime = computeTime  % 86400;
                return year+'年'+month+'个月'+day + '天';
            },
            getQualificationMsg(code){
                Api.getQualificationMsg({markId:code}).then(res=>{
                    var array = []
                    res.data.items.forEach(item=>{
                        array.push(item.img)
                    })
                    this.qualificationList = array
                })
            },
        },
        mounted() {
            this.code = this.$route.query.code
            this.getProcudeMsg(this.$route.query.code)
            this.getQualificationMsg(this.$route.query.code)
        }
    }
</script>

<style scoped lang="scss">
.goodsMsg{
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: PingFang SC;
    font-size: 14px;
    main {
        flex: 1;
        overflow: auto;
        background: #fff;
        padding: 10px 10px ;
        .msg-item{
            display: flex;
            line-height: 40px;
            .title{
                width: 50%;

            }
            .titles{
                width: 100%;
            }
            .trace{
                color: #02a7f0;
                font-weight: 600;
            }
        }
        .qualityDiv{
            .quality{
                font-size: 30px;
                text-align: center;
                font-weight: 600;
                height: 60px;
                line-height: 60px;
            }
            .detailImg{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 20px;
                align-items: center;
                img{
                    width:40%;
                    height: auto;
                    margin-bottom: 30px;
                    vertical-align: middle;
                }
            }
        }

    }

}
</style>