<template>
    <div class="liveStreaming">
        <topHeader msg="云上茶园"></topHeader>
        <main>
            <!--            <div class="mediaDiv">-->
            <!--                <video    :preload="preload"-->
            <!--                       class="video"    align="center" controls="controls"  :autoplay="autoplay">-->
            <!--                    <source :src="videoSrc" type="video/mp4">-->
            <!--                </video>-->
            <!--&lt;!&ndash;                <div class="location">武夷山桐木关寻静茶缘茶园监控机位1</div>&ndash;&gt;-->
            <!--            </div>-->
            <!--            <div class="btnGroup" @click="getVideo">-->
            <!--                <div  class="btn activeBtn btn1" >普洱茶的标准定义</div>-->
            <!--                <div  class="btn btn2" >普洱茶的制作流程</div>-->
            <!--                <div  class="btn btn3" > 普洱茶的正确喝法</div>-->
            <!--                <div  class="btn btn4" >普洱茶的区别</div>-->
            <!--            </div>-->
            <div>
                <img src="../assets/images/nocamera.png" alt="" >
                <span>
                   当前没有开放的机位，请稍后查看
                </span>
            </div>
        </main>
    </div>
</template>

<script>
    import topHeader from "../components/topHeader.vue";
    export default {
        name: "liveStreaming",
        components:{
            topHeader
        },
        data(){
            return{
                preload: 'auto', //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                autoplay: false,
                videoSrc:'https://qx-mtalk.oss-cn-shanghai.aliyuncs.com/mschool/file/2021/07/14/mp4/1626268788771.mp4'
            }
        },
        methods:{
            getVideo(e){
                var that = this
                var isBtn = Array.prototype.slice.call(e.target.classList).some(item=>{
                    return item == 'btn'
                })
                if(isBtn){
                    var btns = document.querySelectorAll('.btn')
                    var btnArray = Array.prototype.slice.call(btns)
                    btnArray.forEach(items=>{
                        Array.prototype.slice.call(items.classList).forEach(item=>{
                            if(item=='activeBtn'){
                                items.classList.remove('activeBtn')
                            }
                        })
                    })
                    e.target.classList.add('activeBtn')
                    var myList = Array.prototype.slice.call(e.target.classList)
                    if(myList.some(item=>{
                        return item == 'btn1'
                    })){
                        let vdo = document.querySelector(".video")
                        vdo.src = 'https://qx-mtalk.oss-cn-shanghai.aliyuncs.com/mschool/file/2021/07/14/mp4/1626268788771.mp4';
                        // vdo.play();

                    }
                    if(myList.some(item=>{
                        return item == 'btn2'
                    })){
                        let vdo = document.querySelector(".video")
                        vdo.src = 'https://qx-mtalk.oss-cn-shanghai.aliyuncs.com/mschool/file/2021/07/14/mp4/1626268745070.mp4';
                        // vdo.play();
                    }
                    if(myList.some(item=>{
                        return item == 'btn3'
                    })){
                        let vdo = document.querySelector(".video")
                        vdo.src = 'https://qx-mtalk.oss-cn-shanghai.aliyuncs.com/mschool/file/2021/07/14/mp4/1626268713000.mp4';
                        // vdo.play();

                    }
                    if(myList.some(item=>{
                        return item == 'btn4'
                    })){
                        let vdo = document.querySelector(".video")
                        vdo.src = 'https://qx-mtalk.oss-cn-shanghai.aliyuncs.com/mschool/file/2021/07/14/mp4/1626270230603.mp4';

                        // vdo.play();
                    }

                }


            }
        }
    }
</script>

<style scoped lang="scss">
    .liveStreaming{
        height: 100%;
        display: flex;
        flex-direction: column;
        font-family: PingFang SC;
        main{
            flex: 1;
            overflow: auto;
            background: #fff;
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 50px;
                img{
                    width: 205px;
                    height: 178px;
                }
                span{
                    font-size: 24px;
                }
            }
            .mediaDiv{
                height: 198px;
                /*background: #42b983;*/
                .video{
                    height: 198px;
                    width: 100%;
                }
                .location{
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 5px;
                }
            }
            .btnGroup{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 30px;
                padding: 15px 10px;

                .btn{
                    width: 170px;
                    height: 40px;
                    background: #ddd;
                    color:#333;
                    line-height: 40px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .activeBtn{
                    color: #fff;
                    background: linear-gradient(163deg, #78DA89 0%, #68CA83 100%);
                    border: none;
                }
            }
        }
    }
</style>