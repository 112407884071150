<template>
  <div :class="single ? 'processItem' : 'myProcessItem'">
    <div class="process-item">
      <div class="img-wrap">
        <div class="wrap">
          <img :src="imgSrc" alt="" class="step" />
        </div>
        <div class="line" v-if="!isLast">
          <img
            src="../assets/images/traceAbility/line.png"
            class="line1"
            alt=""
          />
        </div>
        <div class="arrow" v-if="!isLast">
          <img
            src="../assets/images/traceAbility/arrow.png"
            class="arrow1"
            alt=""
          />
        </div>
      </div>
      <div class="msg">
        <div class="name">{{ name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "processItem1",
  props: {
    imgSrc: {
      type: String,
    },
    name: {
      type: String,
    },
    time: {
      type: String,
    },
    isLast: {
      type: Boolean,
    },
    single: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.processItem {
  width: 40%;
  margin-top: -100px;
  .process-item {
    padding-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-wrap {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      /*overflow: hidden;*/
      margin-bottom: 20px;
      position: relative;
      .wrap {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
      }

      .line {
        display: flex;
        align-items: center;
        position: absolute;
        right: -111%;
        top: 72%;
        transform-origin: left center;
        transform: rotateZ(30deg);
        .line1 {
          width: 102px;
          height: 2px;
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: -65%;
        top: 100%;
        transform-origin: left center;
        transform: rotateZ(30deg);
        .arrow1 {
          width: 7px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .msg {
      text-align: center;
      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
      }
    }
  }
}
.myProcessItem {
  width: 40%;
  padding-left: 32px;
  .process-item {
    padding-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-wrap {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      /*overflow: hidden;*/
      margin-bottom: 20px;
      position: relative;
      .wrap {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
      }
      .line {
        display: flex;
        align-items: center;
        position: absolute;
        right: 73%;
        top: 136%;
        transform-origin: left center;
        transform: rotateZ(-33deg);
        overflow: hidden;
        .line1 {
          width: 102px;
          height: 2px;
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: 110%;
        top: 86%;
        transform-origin: left center;
        transform: rotateZ(145deg);
        .arrow1 {
          width: 7px;
        }
      }
      .step {
        width: 100%;
        height: 100%;
      }
    }
    .msg {
      text-align: center;
      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
      }
    }
  }
}
</style>
